import * as React from 'react'

import * as _ from 'lodash'
import styled from 'styled-components'

import Page from '../components/Page'
import PageHeader from '../components/PageHeader'

import PopularSearchesSection from '../components/sections/PopularSearchesSection'

import Article from '../components/blog/Article'
import Section from '../components/blog/Section'
import Paragraph from '../components/blog/Paragraph'
import Subtitle from '../components/blog/Subtitle'
import Quote from '../components/blog/Quote'
import SimilarPosts from '../components/blog/SimilarPosts'
import AuthorSection from '../components/blog/AuthorSection'

//import * as SegmentAnalytics from '../lib/segment'

import {
  colors,
  Heading,
  Container,
  Row,
  Col,
  media,
  gridLgSpacing,
  MarkdownNode,
  mobilePageOffset,
} from 'cc-ui-components'
import Helmet from 'react-helmet'
import Header from '../components/Header'
import { graphql } from 'gatsby'
import { RouteComponentProps } from '@reach/router'
import IndexLayout from '../components/layout'
import { getValidLangKey } from 'ptz-i18n'
import FullwidthImage from '../components/page/FullwidthImage'

interface BlogPostTemplateProps {
  data: {
    site: {
      siteMetadata: {
        languages: {
          defaultLangKey: string
          langs: []
        }
      }
    }
    contentfulBlogPost: {
      title: string
      subtitle: string
      node_locale: string
      content?: MarkdownNode
      headerImage: {
        file: {
          url: string
        }
        title: string
        description: string
      }
      contentModules: any[]
      topic: {
        title: string
        slug: string
      }
      author: {
        name: string
        authorBio: {
          authorBio: string
        }
        image: {
          file: {
            url: string
          }
        }
        linkedinProfileUrl: string
        twitterProfileUrl: string
        mediumProfileUrl: string
        email: string
      }
      slug: string
      relatedPosts: [any]
    }
  }
}

export const Backdrop = styled.div`
  background-color: ${colors.inverseText} !important;
  padding: 24px 24px 0;
  width: 570px;
  position: absolute;
  bottom: 64px;
  word-break: break-word;
  h2 {
    font-size: 15px !important;
    line-height: 24px;
    margin-bottom: 8px;
  }
  h1 {
    line-height: 44px;
    margin-bottom: 56px;
  }
  ${media.tablet`
    width: calc(100% - 80px);
    max-width: 570px;
  `};
  ${media.sm`
    width: calc(100% - ${mobilePageOffset * 2}px);
    bottom: unset;
    top: 80px;
    right: ${mobilePageOffset}px;
    left: ${mobilePageOffset}px;
    margin-left: 0;
    padding: 24px;
    h1 {
      font-size: 24px;
      line-height: 30px;
      margin-bottom: 0;
    }
    h2 {
      font-size: 12px !important;
      line-height: 16px !important
    }
  `};
`

class BlogPostTemplate extends React.Component<
  BlogPostTemplateProps & RouteComponentProps
> {
  /*componentDidMount(): void {
    const title = _.get(this.props.data, 'contentfulBlogPost.title', '')
    const seoTitle = _.get(
      this.props.data,
      'contentfulBlogPost.seoTitle',
      title
    )
    requestAnimationFrame(() => {
      SegmentAnalytics.page(`Blog / ${seoTitle}`)
    })
  }*/

  render() {
    const { data, location } = this.props
    const { langs, defaultLangKey } = data.site.siteMetadata.languages
    const { content } = data.contentfulBlogPost
    const langKey = getValidLangKey(
      langs,
      defaultLangKey,
      data.contentfulBlogPost.node_locale
    )
    const contentModules =
      !content && data.contentfulBlogPost.contentModules
        ? _.get(data, 'contentfulBlogPost.contentModules') || []
        : null
    const title = _.get(data, 'contentfulBlogPost.title', '')
    const seoTitle = _.get(data, 'contentfulBlogPost.seoTitle', title)
    const seoDescription = _.get(
      data,
      'contentfulBlogPost.seoDescription.seoDescription',
      ''
    )
    // const seoCanonicalUrl = _.get(data, 'contentfulBlogPost.seoCanonicalUrl')

    return (
      <IndexLayout location={location}>
        <Helmet defaultTitle={seoTitle} htmlAttributes={{ lang: langKey }}>
          <title>{seoTitle}</title>
          <meta name="description" content={seoDescription} />
          {/* {seoCanonicalUrl ? <link rel="canonical" href={seoCanonicalUrl} /> : null} */}
          <meta property="og:title" content={seoTitle} />
          <meta property="og:locale" content={langKey} />
          <script
            async
            src="//cdn.embedly.com/widgets/platform.js"
            charSet="UTF-8"
          />
        </Helmet>
        <Header light />
        <FullwidthImage image={data.contentfulBlogPost.headerImage}>
          <PageHeader light padding={'15rem 0 0'}>
            <Row>
              <Col sm={12} md={12} lg={6}>
                <Backdrop>
                  <Heading level="2" size="md" secondary uppercase>
                    {data.contentfulBlogPost.subtitle}
                  </Heading>
                  <Heading level="1" size="xxl" trailer={1}>
                    {title}
                  </Heading>
                </Backdrop>
              </Col>
            </Row>
          </PageHeader>
        </FullwidthImage>
        <Page>
          <Container>
            <Row>
              <Col sm={12} md={12} lg={8.78} xl={gridLgSpacing} center>
                <Article>
                  {!content &&
                    contentModules &&
                    contentModules.map((contentModule: any, keyModule: any) => {
                      const sectionModules = []
                      if (
                        contentModule.__typename === 'ContentfulContentSection'
                      ) {
                        const html = _.get(
                          contentModule,
                          'body.childMarkdownRemark.html'
                        )
                        sectionModules.push(
                          <Subtitle key={`SubTitle_${keyModule}`}>
                            {contentModule.title}
                          </Subtitle>
                        )
                        sectionModules.push(
                          <Paragraph
                            key={`Paragraph_${keyModule}`}
                            highlightInitial={keyModule === 0}
                            dangerouslySetInnerHTML={{ __html: html }}
                          />
                        )
                      }

                      if (
                        contentModule.__typename === 'ContentfulContentQuote'
                      ) {
                        const quote = (
                          <Quote
                            key={`Quotes_${keyModule}`}
                            cite={contentModule.quoteGiver}
                            source={contentModule.source}
                          >
                            {contentModule.quote.quote}
                          </Quote>
                        )
                        sectionModules.push(quote)
                      }

                      return (
                        <Section key={`Section_${keyModule}`}>
                          {sectionModules}
                        </Section>
                      )
                    })}
                  {content &&
                    content.childMarkdownRemark &&
                    content.childMarkdownRemark.html && (
                      <Section>
                        <Paragraph
                          highlightInitial
                          dangerouslySetInnerHTML={{
                            __html: content.childMarkdownRemark.html,
                          }}
                        />
                      </Section>
                    )}
                  <AuthorSection
                    name={_.get(data, 'contentfulBlogPost.author.name')}
                    authorBio={_.get(
                      data,
                      'contentfulBlogPost.author.authorBio'
                    )}
                    image={_.get(data, 'contentfulBlogPost.author.image')}
                    linkedinProfileUrl={_.get(
                      data,
                      'contentfulBlogPost.author.linkedinProfileUrl'
                    )}
                    twitterProfileUrl={_.get(
                      data,
                      'contentfulBlogPost.author.twitterProfileUrl'
                    )}
                    mediumProfileUrl={_.get(
                      data,
                      'contentfulBlogPost.author.mediumProfileUrl'
                    )}
                    email={_.get(data, 'contentfulBlogPost.author.email')}
                  />
                </Article>
              </Col>
              <Col sm={12} md={12} lg={gridLgSpacing} center>
                <SimilarPosts items={data.contentfulBlogPost.relatedPosts} />
              </Col>
            </Row>
          </Container>
          <PopularSearchesSection sm={12} md={12} lg={gridLgSpacing} />
        </Page>
      </IndexLayout>
    )
  }
}

export default BlogPostTemplate

export const query = graphql`
  query BlogPostTemplateQuery($id: String!) {
    site {
      siteMetadata {
        languages {
          defaultLangKey
          langs
        }
      }
    }
    contentfulBlogPost(id: { eq: $id }) {
      title
      subtitle
      node_locale
      seoTitle
      seoDescription {
        seoDescription
      }
      seoCanonicalUrl
      content {
        childMarkdownRemark {
          html
        }
      }
      headerImage {
        file {
          url
        }
        title
        description
      }
      contentModules {
        __typename
        ... on ContentfulContentSection {
          title
          subtitle
          body {
            body
            childMarkdownRemark {
              html
            }
          }
        }
        ... on ContentfulContentQuote {
          quote {
            quote
          }
          quoteGiver
          source
        }
      }
      topic {
        title
      }
      author {
        name
        authorBio {
          authorBio
        }
        image {
          title
          description
          file {
            url
          }
        }
        linkedinProfileUrl
        twitterProfileUrl
        mediumProfileUrl
        email
      }
      slug
      relatedPosts {
        slug
        title
        node_locale
        updatedAt(formatString: "DD MMMM YYYY")
        topic {
          title
        }
        headerImage {
          fluid(maxWidth: 400, resizingBehavior: SCALE) {
            ...GatsbyContentfulFluid
          }
        }
      }
    }
  }
`
