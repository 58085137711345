import * as React from 'react'

import { Heading } from 'cc-ui-components'

interface SubtitleProps {
  children: {}
  leader?: number
  trailer?: number
}

const Subtitle: React.SFC<SubtitleProps> = ({ children, ...rest }) => (
  <Heading size="xxl" leader={8} trailer={2.5} {...rest}>
    {children}
  </Heading>
)

export default Subtitle
