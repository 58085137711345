import * as React from 'react'
import styled from 'styled-components'

import Separator from './Separator'
import { brandColors, colors } from 'cc-ui-components'

interface SectionProps {
  children: {}
}

const StyledSection = styled.section`
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    text-rendering: optimizeLegibility;
    color: #2d2d2d;
    margin-block-start: 0;
    margin-block-end: 0;
    line-height: 1em;
    margin-top: 1.5em;
    margin-bottom: 0.45em;
    font-weight: 400;
    letter-spacing: -1.4px;
  }
  h2 {
    font-size: 30px;
  }
  h3 {
    font-size: 22px;
  }
  blockquote {
    font-style: italic;
    padding: 15px;
    margin: 15px auto;
    background: ${colors.lightBackground};
    border-left: 5px solid ${brandColors.lightgrey4};
  }
  table {
    width: 100%;
    border-spacing: 0;
  }
  th,
  td {
    border: 1px solid ${brandColors.lightgrey4};
  }
  td {
    background: ${colors.lightBackground};
  }
  td,
  th {
    padding: 7px;
    text-align: left;
  }
  pre {
    background: ${colors.lightBackground};
    code {
      color: ${colors.blogText};
    }
  }
  li {
    margin: 0.5em 0;
    padding-left: 0.3em;
    line-height: 1.6em;
  }
  hr {
    border-color: ${colors.lightBackground};
  }
`

const Section: React.SFC<SectionProps> = ({ children, ...rest }) => (
  <StyledSection {...rest}>
    {children}
    <Separator leader={8} trailer={8} height={2} />
  </StyledSection>
)

export default Section
