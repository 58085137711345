import * as React from 'react'
import styled, { css } from 'styled-components'

import { spacing, brandColors } from 'cc-ui-components'

interface SeparatorProps {
  leader?: number
  trailer?: number
  height?: number
}

const styles = (props: SeparatorProps) => css`
  max-width: 367px;
  width: 36.2%;
  margin: ${spacing(props.leader || 0)} auto ${spacing(props.trailer || 0)};
  border: none;
  height: ${props.height || 1}px;
  background: ${brandColors.lightgrey2};
`

const StyledSeparator = styled.hr`
  ${styles};
`

const Separator: React.SFC<SeparatorProps> = ({
  leader = 4,
  trailer = 4,
  height = 1,
  ...rest
}) => (
  <StyledSeparator
    leader={leader}
    trailer={trailer}
    height={height}
    {...rest}
  />
)

export default Separator
