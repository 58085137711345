import * as React from 'react'
import styled, { css } from 'styled-components'

import { Heading, Row, Col, spacing } from 'cc-ui-components'

interface QuoteProps {
  children: {}
  cite: string
  source: string
  leader?: number
  trailer?: number
}

interface WrapperProps {
  leader?: number
  trailer?: number
}

const Blockquote = styled.blockquote`
  margin: 0;
`

const Wrapper = styled.div`
  ${(props: WrapperProps) =>
    css`
      margin: ${spacing(props.leader || 0)} 0 ${spacing(props.trailer || 0)};
    `};
`

const Quote: React.SFC<QuoteProps> = ({
  leader = 6,
  trailer = 6,
  cite,
  source,
  children,
  ...rest
}) => (
  <Wrapper leader={leader} trailer={trailer}>
    <Row>
      <Col center sm={12} md={10} lg={9}>
        <Blockquote>
          <Heading
            level="4"
            size="xl"
            leader={3}
            trailer={3}
            primary
            center
            {...rest}
          >
            {children}
          </Heading>
          <Heading level="6" size="xl" center {...rest}>
            {cite}
          </Heading>
        </Blockquote>
      </Col>
    </Row>
  </Wrapper>
)

export default Quote
