import * as React from 'react'
import styled, { css } from 'styled-components'

import { colors, Text as BaseText } from 'cc-ui-components'

interface ParagraphProps {
  children?: {}
  highlightInitial?: boolean
  leader?: number
  trailer?: number
  dangerouslySetInnerHTML?: { __html: string }
}

const styles = (props: ParagraphProps) => css`
  color: #1f1f1f;
  line-height: 1.92rem;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  ${props.highlightInitial &&
    css`
      &:first-letter {
        float: left;
        color: ${colors.primary};
        font-size: 4.44em;
        line-height: 1em;
        padding-right: 0.25em;
      }
    `};
`

const Text = styled(BaseText)`
  ${styles};
`

const Paragraph: React.SFC<ParagraphProps> = ({
  highlightInitial = false,
  children,
  ...rest
}) => (
  <Text
    size="lg"
    tagName="div"
    secondary
    leader={2.5}
    trailer={2.5}
    highlightInitial={highlightInitial}
    {...rest}
  >
    {children}
  </Text>
)

export default Paragraph
