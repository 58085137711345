import * as React from 'react'

import * as _ from 'lodash'
import { Heading } from 'cc-ui-components'
import BlogList from '../common/BlogList'
import styled from 'styled-components'
import { injectIntl, InjectedIntlProps } from 'react-intl'

interface IPost {
  slug: string
  title: string
  headerImage: any
  updatedAt: string
  topic: {
    title: string
  }
  node_locale: string
}

interface SimilarPostsProps extends InjectedIntlProps {
  items: IPost[]
}

const Wrapper = styled.div`
  margin-top: 104px;
  h5 {
    line-height: 44px;
    margin-bottom: 56px;
  }
`

const SimilarPosts: React.SFC<SimilarPostsProps> = ({ intl, items }) => {
  if (!items || !items.length) {
    return null
  }
  const lang: string = intl.locale
  const articles = items.map(item => ({
    ...item,
    image: item.headerImage,
    pubDate: item.updatedAt,
    link: `/${lang}/blog/${item.slug}`,
    category: _.get(item, 'topic.0.title'),
  }))
  return (
    <Wrapper>
      <Heading level="5" size="xxl">
        You might also like:
      </Heading>
      <BlogList articles={articles} trailer={16} />
    </Wrapper>
  )
}

export default injectIntl(SimilarPosts)
