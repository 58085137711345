import styled from 'styled-components'

import { spacing } from 'cc-ui-components'

const Article = styled.article`
  margin-top: ${spacing(10)};
  padding-bottom: ${spacing(2)};
`

export default Article
