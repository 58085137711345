import * as React from 'react'
import styled from 'styled-components'

import {
  Row,
  Col,
  spacing,
  media,
  LinkedinIcon,
  TwitterIcon,
  MediumIcon,
  Heading,
  Text,
  gridLgSpacing,
} from 'cc-ui-components'

import EmailSVG from '../../assets/images/icons/email.svg'
import { injectIntl, InjectedIntlProps, FormattedMessage } from 'react-intl'
import { brandColors } from 'cc-ui-components/dist'

interface AuthorSectionProps extends InjectedIntlProps {
  name: string
  authorBio: {
    authorBio: string
  }
  image: {
    title: string
    description: string
    file: {
      url: string
    }
  }
  linkedinProfileUrl?: string
  twitterProfileUrl?: string
  mediumProfileUrl?: string
  email?: string
}

const Footer = styled.footer`
  margin: 0 0 ${spacing(8)} 0;
`

const AuthorDescriptionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 30px;
  h4 {
    margin-bottom: 16px;
  }
  p {
    line-height: 24px;
    font-size: 18px;
    flex: 1;
  }
  ${media.sm`
    margin-left: 0;
    margin-top: 30px;
    align-items: center;
    p, h4 {
      text-align: center;
    }
    p {
      line-height: 24px;
      margin-bottom: 15px;
    }
  `};
`

const AuthorLinks = styled.div`
  display: flex;

  a {
    display: inline-flex;
    margin-right: 14%;
  }

  svg {
    height: 32px;
    width: auto;
  }
`

const Image = styled.img`
  width: 186px;
  height: 186px;
  object-fit: cover;
`

const AuthorWrapper = styled.div`
  display: flex;
  ${media.sm`
    flex-direction: column;
    align-items: center;
  `};
`

const AuthorSection: React.SFC<AuthorSectionProps> = ({
  authorBio,
  image,
  linkedinProfileUrl,
  twitterProfileUrl,
  mediumProfileUrl,
  email,
}) => (
  <Footer>
    <Row>
      <Col sm={12} md={12} lg={gridLgSpacing}>
        <AuthorWrapper>
          {image && (
            <Image
              src={`${image.file.url}?w=300&h=300&fm=jpg`}
              alt={image.title}
            />
          )}
          <AuthorDescriptionWrapper>
            <Heading level="4" size="md" secondary uppercase>
              <FormattedMessage id="blog.article.author_section.headline" />
            </Heading>
            {authorBio && (
              <Text size="xl" trailer={2}>
                {authorBio.authorBio}
              </Text>
            )}
            <AuthorLinks>
              {linkedinProfileUrl && (
                <a href={linkedinProfileUrl}>
                  <LinkedinIcon fill={brandColors.darkgrey2} />
                </a>
              )}
              {twitterProfileUrl && (
                <a href={twitterProfileUrl}>
                  <TwitterIcon />
                </a>
              )}
              {mediumProfileUrl && (
                <a href={mediumProfileUrl}>
                  <MediumIcon />
                </a>
              )}
              {email && (
                <a href={`mailto:${email}`}>
                  <EmailSVG />
                </a>
              )}
            </AuthorLinks>
          </AuthorDescriptionWrapper>
        </AuthorWrapper>
      </Col>
    </Row>
  </Footer>
)

export default injectIntl(AuthorSection)
